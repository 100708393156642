<template>
  <div class="content">
    <div class="m-card my-customer">
      <Tabs value="name1" class="tab-one">
        <TabPane label="Contact us" name="name1">
          <div class="my-customer-main">
            <div class="host">
              <div class="title">
                <i class="icon iconfont icon-zixun1"></i>
                <div class="title-r">
                  <h2>Organzier</h2>
                  <p>Consultation on exhibition participation and visit</p>
                </div>
              </div>

              <div class="host-list">
                <Row style="width:100%">
                  <iCol span="8" v-for="(item, index) in hostList" :key="index">
                    <div class="yd-list-one">
                      <div class="yd-list-l">
                        <h4>
                          {{ item.fullName }}<span>{{ item.title }}</span>
                        </h4>
                        <p class="phone">
                          Mobile：<span>{{ item.phoneNumber }}</span>
                        </p>
                        <p class="QQ">
                          QQ：<a target="_blank" :href="'http://wpa.qq.com/msgrd?v=3&uin=' + item.qQId + '&site=qq&menu=yes'">{{ item.qQId }}</a>
                        </p>
                        <p class="phone">
                          email：<span title="item.email">{{ item.email }}</span>
                        </p>
                      </div>
                      <div class="yd-list-r">
                        <div class="code" v-if="item.weChatQrCode">
                          <img :src="item.weChatQrCode" @click="showQcode(item.weChatQrCode)" />
                          <p style="font-size:12px;">Scan code</p>
                        </div>
                      </div>
                    </div>
                  </iCol>
                </Row>
              </div>
            </div>

            <div class="host" v-if="zsxList.length">
              <div class="title">
                <i class="icon iconfont icon-zixun2"></i>
                <div class="title-r">
                  <h2>联系展商秀官方</h2>
                  <p>解答展商秀产品相关问题</p>
                </div>
              </div>

              <div class="host-list">
                <div class="yd-list-one" v-for="(item, index) in zsxList" :key="'zsx' + index">
                  <div class="yd-list-l">
                    <h4>
                      {{ item.fullName }}<span>{{ item.title }}</span>
                    </h4>
                    <p class="phone">
                      Mobile：<span>{{ item.phoneNumber }}</span>
                    </p>
                    <p class="QQ">
                      QQ：<span>{{ item.qQId }}</span>
                    </p>
                  </div>
                  <div class="yd-list-r">
                    <div class="code">
                      <img :v-if="item.weChatQrCode" :src="item.weChatQrCode" />
                      <p>Scan code to view</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
    <Modal v-model="modal3" :footer-hide="true" :mask-closable="false" class-name="vertical-center-modal" width="300px">
      <div style="text-align: center; padding-top: 40px; padding-bottom: 40px">
        <img :src="codeImg" style="width: 180px; height: 180px" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "my-customer",
  data() {
    return {
      hostList: [],
      zsxList: [],
      codeImg: "",
      modal3: false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
    }),
    async getData() {
      let query = `
      query($where:String!){
        customerServiceQuery{
          getList(where:$where){
              fullName
              phoneNumber
              qQId
              title
              type
              weChatId
              weChatQrCode   
              email        
            }                   
          }
        }
      `;
      let where = {
        equal_isDeleted: {
          n: "isDeleted",
          v: 0,
        },
        Equal_lang: { N: "langVersion", V: "en" },
        or: {
          equal_exhibitionId: {
            n: "exhibitionId",
            v: this.exhibitionId,
          },
          equal_host: {
            n: "hostId",
          },
        },
      };
      let opt = {
        query: query,
        variables: {
          where: JSON.stringify(where),
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.customerServiceQuery.getList));
      let host = [];
      let zsx = [];
      res.forEach((e) => {
        if (e.type == "host") {
          host.push(e);
        } else {
          zsx.push(e);
        }
      });
      this.hostList = host;
      this.zsxList = zsx;
    },
    showQcode(url) {
      this.modal3 = true;
      this.codeImg = url;
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
::v-deep {
  .ivu-modal {
    width: 200px;
  }
  .vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .ivu-modal {
      top: 0;
    }
  }
}

.content {
  .m-card {
    border: solid 1px #ededed;
    min-height: 700px;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}
.my-customer-main {
  padding: 0 20px;
  .title {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .icon {
      font-size: 26px;
      @include font_color(#1890ff);
      margin-right: 14px;
    }
    .icon-zixun2 {
      color: #fc7b7f;
    }
    &-r {
      h2 {
        font-weight: normal;
        font-size: 16;
        color: #333;
        margin-bottom: 4px;
      }
      p {
        color: #999;
      }
    }
  }
}
.host-list {
  display: flex;
  align-items: center;
}
.yd-list-l {
  width: calc(100% - 80px);
}
.yd-list-r {
  width: 80px;
}
.yd-list-one {
  background: #f5f5f5;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 15px;
  margin-right: 20px;
  h4 {
    font-size: 16px;
    color: #333;
    span {
      font-size: 12px;
      color: #333;
      margin-left: 3px;
    }
  }
  p {
    font-size: 14px;
    color: #333;
    line-height: 1.8;
    width: calc(100% - 1px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .code {
    width: 80px;
    height: 67px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    img {
      width: 45px;
      height: 45px;
      display: block;
    }
    p {
      font-size: 12px;
      color: #666;
      margin-top: 10px;
    }
  }
}
</style>
